<template>
  <div class="warp">
    <div class="header">
      <van-nav-bar
          title="章节练习"
          left-arrow fixed
          @click-left="goBack"
      />
    </div>

    <div class="bodyWarp" v-if="list&&list.length>0">
      <div v-for="(item,index) in list" :key="item.chapterId">
        <div class="clear title" @click="showItem(index,item)">
          <div class="left" style="width: 70%;">
            <img v-if="item.show" class="icon" src="@/assets/all_Icon/drawable-xxhdpi/practice_del.png"/>
            <img v-else class="icon" src="@/assets/all_Icon/drawable-xxhdpi/practice_add.png"/>
            <span>{{ item.chapterName }}</span>
            <div style="width: 100%;">
              <div class="question_cirle study">
                <div class="question_cirle-continer">
                  <div class="question_cirle-yes" :style="setCirleNum(item)"></div>
                </div>
                <span>{{ item.completeNumber }}/{{ item.countNumber }}</span>
              </div>
            </div>
          </div>
          <div class="right btn">开始学习</div>
        </div>

        <ul class="bg" v-show="item.show">
          <li class="clear detial" v-for="val in item.nodeList" :key="val.nodeId">
            <p>{{ val.nodeName }}</p>
            <div class="question_cirle">
              <div class="question_cirle-continer">
                <div class="question_cirle-yes" :style="setCirleNum(val)"></div>
              </div>
              <span>{{ val.completeNumber }}/{{ val.countNumber }}</span>
            </div>
            <div class="btns btn report" v-show="val.isJiaojuan == 1" @click="btnClick('report',val)">答题报告</div>
            <div class="btns btn again" v-show="val.isJiaojuan == 1" @click="btnClick('again',val)">重新答题</div>
            <div class="btns btn" v-show="val.isJiaojuan==0" @click="btnClick('continueQuestions',val)">继续答题</div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <van-empty description="暂无数据"></van-empty>
    </div>

  </div>
</template>

<script>
import {getExerciseList} from "@/api/questionBank.js";

export default {
  data() {
    return {
      list: [],
      activeId: ''
    }
  },
  name: 'index',
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    showItem(index, item) {
      this.list[index].show = !item.show;
      this.list.forEach((v, i) => {
        if (index === i) {
        } else {
          v.show = false;
        }
      })
      this.$forceUpdate();
    },
    setCirleNum(item) {
      let num = item.completeNumber / item.countNumber * 100 + "%";
      return {
        "width": num
      }
    },
    btnClick(type, item) {
      this.$store.commit('setResolution_method',{key:'header_res'});
      if (type === 'report') {
        //答题报告

        this.$router.push({
          path:"/answerReport",
          query:{
            id:item.nodeId,
            path:"/chapterExercisesDetail",
            source: 'list',
            type: 'chapterExercises'
          }
        })
      } else if (type === 'again') {
        // 重新答题
        this.$router.push({
          path:"/chapterExercisesDetail",
          query:{
            id:item.nodeId,
            type: 'rest'
          }
        })
      } else if (type === 'continueQuestions') {
        // 继续答题
        this.$router.push({
          path:"/chapterExercisesDetail",
          query:{
            id:item.nodeId,
            type: 'continue'
          }
        })
      }
    },
    getExerciseList() {
      let param = {
        secondId: this.secondId,
        type: 1
      }
      getExerciseList(param).then((res) => {
        console.log(res);
        if (res.data.code === 0 && res.data.data.length) {
          this.list = res.data.data;
          this.list.forEach((v, i) => {
            v.show = false;
          })
          this.list[0].show = true;
        }
      })
    },
  },
  created() {
    this.secondId = this.$route.query.id;
    this.getExerciseList();
  },
  beforeDestory() {

  }
}
</script>

<style lang="less" scoped>
.header {
  font-size: 17px;
  font-weight: 500;
  color: #141414;
  line-height: 24px;
}

/deep/ .van-nav-bar__title {
  font-size: 17px;
  font-weight: 500;
  color: #141414;
}

/deep/ .header .van-icon-arrow-left {
  color: #000;
}

.bodyWarp {
  margin-top: 42px;
  height: calc(100vh - 46px);
  overflow-y: auto;
  .title {
    height: 19px;
    line-height: 19px;
    padding: 22px 10px;

    .icon {
      width: 14px;
      height: 14px;
      vertical-align: sub;
      margin-right: 6px;
    }
  }

  .detial {
    margin: 0 10px 0 40px;
    padding: 14px 0 14px 0;
    position: relative;

    .btns {
      position: absolute;
      right: 0;
      top: 38px;
      margin-top: -19px;
    }

    .again {
      position: absolute;
      right: 0;
      top: 38px;
      margin-top: -19px;
      background: #EA6A76;
    }

    .report {
      position: absolute;
      right: 70px;
      top: 38px;
      margin-top: -19px;
    }
  }

  .bg {
    background: #F8F8F8;

    p {
      //height: 19px;
      line-height: 19px;
      width: calc(100% - 160px);
    }
  }

  .btn {
    height: 28px;
    line-height: 28px;
    background: #5D7DFF;
    border-radius: 5px;
    color: #fff;
    padding: 0 7px;
  }
}

.question_cirle {
  width: calc(100% - 160px);
  height: 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;

  .question_cirle-continer {
    width: calc(100% - 40px);
    height: 10px;
    background: #E7ECFF;
    border-radius: 5px;
  }

  .question_cirle-yes {
    width: 0;
    height: 10px;
    background: #5F7DFF;
    border-radius: 5px;

  }
}

.study {
  width: calc(100% - 90px);
  margin-left: 20px;
}

.clear {
  clear: both;
  overflow: hidden;
  content: '';
}

.left {
  float: left;
}

.right {
  float: right;
}
</style>
